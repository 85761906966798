import { setLanguage } from "@shared/i18n";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export type TranslationKey =
	["Plus_Signup_Header_NoCommitmentCancelAnytime"] |
	["Plus_Signup_Header_SubscriptionWillRenewAutomatically"] |
	["Plus_Signup_Footer_Contact"] |
	["Plus_Signup_Content_Text", { planName: string }] |
	["Plus_Signup_Content_YouAreAMember"] |
	["Plus_Signup_Content_ShortYear"] |
	["Plus_Signup_Content_ShortMonth"] |
	["Plus_Signup_Content_SignupNow"] |
	["Plus_Signup_Content_UpgradeButton"] |
	["Plus_Signup_Content_UpgradeText"] |
	["Plus_Signup_Confirmation_SuccesMessageTitle"] |
	["Plus_Signup_Confirmation_SuccesMessage"] |

	["GiftCard"] |
	["GiftCard_Btn_Buy"] |
	["GiftCard_Btn_CustomAmount"] |
	["GiftCard_Text_Custom"] |

	["GiftCard_Text"] |
	["GiftCard_FAQ_Title"] |
	["GiftCard_FAQ_Text"] |
	["GiftCard_FAQ_WhereToBuyGiftCard_Title"] |
	["GiftCard_FAQ_WhereToBuyGiftCard_Text", { link: string }] |
	["GiftCard_FAQ_HowLongIsGiftCardValidFor_Title"] |
	["GiftCard_FAQ_HowLongIsGiftCardValidFor_Text"] |
	["GiftCard_FAQ_HowIsGiftCardReceived_Title"] |
	["GiftCard_FAQ_HowIsGiftCardReceived_Text"] |
	["GiftCard_FAQ_WhereCanGiftCardBeUsed_Title"] |
	["GiftCard_FAQ_WhereCanGiftCardBeUsed_Text"] |
	["GiftCard_FAQ_NeedProfileToBuy_Title"] |
	["GiftCard_FAQ_NeedProfileToBuy_Text"] |
	["GiftCard_FAQ_TermsOfUse_Title"] |
	["GiftCard_FAQ_TermsOfUse_Text", { link1: string, link2: string }] |
	["GiftCard_FAQ_NoEmailReceived_Title"] |
	["GiftCard_FAQ_NoEmailReceived_Text", { link: string }] |

	["GiftCard_Form_Title"] |
	["GiftCard_Form_Text"] |
	["GiftCard_Form_Text_NoLogin"] |
	["GiftCard_Form_AlreadyUser"] |
	["GiftCard_Form_EnterAnAmount_Label"] |
	["GiftCard_Form_EnterAnAmount_HelperText"] |
	["GiftCard_Form_FirstName_Label"] |
	["GiftCard_Form_LastName_Label"] |
	["GiftCard_Form_Email_HelperText"] |
	["GiftCard_Form_Terms_Label", { link: string }] |
	["GiftCard_Form_Btn"] |
	["GiftCard_Email_Validation_Invalid_Title"] |
	["GiftCard_Email_Validation_Invalid_Text"] |
	["GiftCard_Email_Validation_Suggestion_Title"] |
	["GiftCard_Email_Validation_Suggestion_Text", { email: string, suggestion: string }] |
	["GiftCard_Email_Validation_Suggestion_Btn_Back"] |
	["GiftCard_Email_Validation_Suggestion_Btn_Confirm"] |

	["GiftCard_SuccessPage_PleaseWait"] |
	["GiftCard_SuccessPage_Error_Title"] |
	["GiftCard_SuccessPage_Error_Text", { link: string }] |
	["GiftCard_SuccessPage_Success_Title"] |
	["GiftCard_SuccessPage_Success_Text"] |
	["GiftCard_SuccessPage_Success_Box_Headline"] |
	["GiftCard_SuccessPage_Success_Box_Text"] |
	["GiftCard_SuccessPage_Success_Text_ReadMore", { link1: string, link2: string }] |


	["Plus_Signup_Family_Subheadline"] |
	["Plus_Signup_Family_SignUpButton"] |
	["Plus_Signup_Family_SignUpButton_ExtraText"] |
	["Plus_Signup_Family_AllBenefits_Title"] |
	["Plus_Signup_Family_FAQ_Title"] |

	["Plus_Signup_Family_LowerShippingPrices_Subheader"] |
	["Plus_Signup_Family_ServiceFeeSaving_Subheader"] |
	["Plus_Signup_Family_FeatureCreditsPerMont_Subheader"] |
	["Plus_Signup_Family_RetailSavings_Header"] |
	["Plus_Signup_Family_RetailSavings_Subheader"] |

	["Plus_Signup_Family_LowerShippingPrices_Title"] |
	["Plus_Signup_Family_LowerShippingPrices_Text", { link: string }] |
	["Plus_Signup_Family_RetailSavings_Title"] |
	["Plus_Signup_Family_RetailSavings_Text"] |
	["Plus_Signup_Family_FeatureCreditsPerMont_Title"] |
	["Plus_Signup_Family_FeatureCreditsPerMont_Text"] |
	["Plus_Signup_Family_ServiceFeeSaving_Title"] |
	["Plus_Signup_Family_ServiceFeeSaving_Text", {link: string}] |
	["Plus_Signup_Family_NoAdsInApp_Title"] |
	["Plus_Signup_Family_NoAdsInApp_Text"] |
	["Plus_Signup_Family_FeaturePrioritizedItems_Title"] |
	["Plus_Signup_Family_FeaturePrioritizedItems_Text"] |
	["Plus_Signup_Family_Credibility_Title"] |
	["Plus_Signup_Family_Credibility_Text"] |
	["Plus_Signup_Family_ResaleService_Title"] |
	["Plus_Signup_Family_ResaleService_Text"] |

	["Plus_Signup_Family_Terms_One"] |
	["Plus_Signup_Family_Terms_Two"] |

	["Plus_Signup_Family_Help_Title_Member"] |
	["Plus_Signup_Family_Help_Text_Member"] |
	["Plus_Signup_Family_Help_Title_MemberPricer"] |
	["Plus_Signup_Family_Help_Text_MemberPricer"] |
	["Plus_Signup_Family_Help_Title_BindingPeriod"] |
	["Plus_Signup_Family_Help_Text_BindingPeriod"] |
	["Plus_Signup_Family_Help_Title_CancelPlus"] |
	["Plus_Signup_Family_Help_Text_CancelPlus", { mail: string }] |

	["Plus_Signup_Family_Error_Title_SubscriptionIssue"] |

	["Plus_Signup_Business_PaymentSystem_Text"] |
	["Plus_Signup_Business_Shipping_Text"] |
	["Plus_Signup_Business_AutoPayment_Text"] |
	["Plus_Signup_Business_Fee_Text"] |
	["Plus_Signup_Business_Credits_Text"] |

	["Plus_Signup_Content_AdditionalBusinessTerms"] |



	["BuyerProtection_Title"] |
	["BuyerProtection_Text", { priceForMembers: string, percentageForMembers: string, priceForNonMembers: string, percentageForNonMembers: string }] |
	["BuyerProtection_Label"] |

	["BuyerProtection_Benefit_One_Titel"] |
	["BuyerProtection_Benefit_One_Text"] |
	["BuyerProtection_Benefit_Two_Titel"] |
	["BuyerProtection_Benefit_Two_Text"] |
	["BuyerProtection_Benefit_Three_Titel"] |
	["BuyerProtection_Benefit_Three_Text"] |
	["BuyerProtection_Benefit_Four_Titel"] |
	["BuyerProtection_Benefit_Four_Text"] |
	["BuyerProtection_Benefit_Five_Titel"] |
	["BuyerProtection_Benefit_Five_Text"] |
	["BuyerProtection_Benefit_Six_Titel"] |
	["BuyerProtection_Benefit_Six_Text"] |

	["BuyerProtection_Faq_Title"] |

	["BuyerProtection_Faq_One_Title"] |
	["BuyerProtection_Faq_One_Text", { priceForMembers: string, percentageForMembers: string, priceForNonMembers: string, percentageForNonMembers: string }] |
	["BuyerProtection_Faq_Two_Title"] |
	["BuyerProtection_Faq_Two_Text"] |
	["BuyerProtection_Faq_Three_Title"] |
	["BuyerProtection_Faq_Three_Text"] |
	["BuyerProtection_Faq_Four_Title"] |
	["BuyerProtection_Faq_Four_Text"] |
	["BuyerProtection_Faq_Five_Title"] |
	["BuyerProtection_Faq_Five_Text"] |
	["BuyerProtection_Faq_Six_Title"] |
	["BuyerProtection_Faq_Six_Text"] |
	["BuyerProtection_Faq_Seven_Title"] |
	["BuyerProtection_Faq_Seven_Text"] |
	["BuyerProtection_Faq_Eight_Title"] |
	["BuyerProtection_Faq_Eight_Text"] |
	["BuyerProtection_Faq_Nine_Title"] |
	["BuyerProtection_Faq_Nine_Text"] |


	["LogIn_Text"] |
	["LogIn_Email"] |
	["LogIn_Password"] |
	["LogIn_Error"] |
	["New_User_Text"] |
	["LinkText_Download_App"] |
	["LinkText_Go_To_Frontpage"] |
	["ButtonText_Download_App"] |
	["See_More_Text"] |
	["See_Less_Text"] |

	["Credits_Content_InfoText"] |
	["Credits_Banner_TitleText1"] |
	["Credits_Banner_TitleText2"] |
	["Credits_Banner_Text"] |
	["Credits_Banner_Btn"] |
	["Confirmation_Credits_SuccesMessageTitle"] |
	["Confirmation_Credits_SuccesMessage"] |

	["Account_Reset_Password_Title"] |
	["Account_Reset_Password_Hint"] |
	["Account_Reset_Password_NoPasswordSpecified"] |
	["Account_Reset_Password_LinkExpired"] |
	["Account_Reset_Password_PasswordMustBeAtLeastCharacters", { length: number }] |
	["Account_Reset_Password_Update"] |
	["Information_Card_Payment_On_File"] |

	["Web_Dialog_Confirm"] |
	["Web_Dialog_Cancel"] |

	["Update_Available_Header"] |
	["Update_Available_Subheader"] |
	["Update_Available_Text"] |
	["Update_Available_ButtonText"] |
	["Update_Available_AndroidText"] |

	["Receipts_NotFound"] |
	["Payment_Error_Toast", { error: string }];

export type Translation<TKey extends string, TVariables extends object> = [
	TKey,
	TVariables
];

type TranslationObject = {
	[P in TranslationKey[0]]: string;
}

type TranslationDictionary = {
	[languageCode: string]: {
		translation: TranslationObject
	}
}

const resources: TranslationDictionary = {
	en: {
		translation: {
			Plus_Signup_Header_NoCommitmentCancelAnytime: "No binding period and no ads.",
			Plus_Signup_Header_SubscriptionWillRenewAutomatically: "The subscription will renew automatically.",
			Plus_Signup_Footer_Contact: "Contact",
			Plus_Signup_Content_Text: "Confirm your plan change to {{planName}}",
			Plus_Signup_Content_YouAreAMember: "Current Plan",
			Plus_Signup_Content_ShortYear: "yr",
			Plus_Signup_Content_ShortMonth: "mo",
			Plus_Signup_Content_SignupNow: "Sign up now",
			Plus_Signup_Content_UpgradeButton: "Upgrade",
			Plus_Signup_Content_UpgradeText: "your plus subscription will get upgraded instantly and the price difference for the remaining subscription period will be charged to you card immediately",
			Plus_Signup_Confirmation_SuccesMessageTitle: "Congratulations!",
			Plus_Signup_Confirmation_SuccesMessage: "You're now Plus Member and can return to the app 🙂",


			GiftCard: "Gift card",
			GiftCard_Btn_Buy: "Buy",
			GiftCard_Btn_CustomAmount: "Select amount",
			GiftCard_Text_Custom: "Select",
			GiftCard_Text: "A Reshopper gift card will please any family with children for any occasion - e.g. christening, baby shower, birthday or Christmas present. The gift card can be used to buy second-hand items from other families and new surplus items in our app.",
			GiftCard_FAQ_Title: "A meaningful gift card",
			GiftCard_FAQ_Text: "With a gift card from Reshopper, you can give a meaningful gift that pleases both the children's family, the environment and the wallet 🤩♻️💰<br /><br />Reshopper is Denmark's largest marketplace for families with children, where you can buy and sell secondhand items for children, mother and home. The families with children create more than 300,000 new secondhand items every month. In addition to secondhand items, Reshopper also has a smaller range of new surplus items from leftover lots and previous collections, where you always save at least 50%. That's why money really goes a long way at Reshopper.",
			GiftCard_FAQ_WhereToBuyGiftCard_Title: "Where do I buy a gift card?",
			GiftCard_FAQ_WhereToBuyGiftCard_Text: "Gift cards can currently only be purchased online at Reshopper.com/gavekort.",
			GiftCard_FAQ_HowLongIsGiftCardValidFor_Title: "How long is a gift card valid for?",
			GiftCard_FAQ_HowLongIsGiftCardValidFor_Text: "The gift card is valid for 1 year from the date of issue. The issue date will be visible on the gift card.",
			GiftCard_FAQ_HowIsGiftCardReceived_Title: "How is the gift card received?",
			GiftCard_FAQ_HowIsGiftCardReceived_Text: "The gift card is electronic and is sent via e-mail.<br />When you buy a gift card online, it is issued in electronic form and you will be sent a digital gift card code to your e-mail, which you can print out or forward.",
			GiftCard_FAQ_WhereCanGiftCardBeUsed_Title: "Where and how can the gift card be used?",
			GiftCard_FAQ_WhereCanGiftCardBeUsed_Text: "The gift card can be used for the purchase of new surplus items and second-hand items from other families in our app when you buy with Reshopper Freight (cannot be used for a local trade yet).<br/>When you use the gift card in a private trade with Reshopper Freight in the app, the money is reserved when the bid is submitted. If the purchase amount exceeds the value of the gift card, it can be combined with other payment methods.",
			GiftCard_FAQ_NeedProfileToBuy_Title: "Do I need a profile on Reshopper to buy a gift card?",
			GiftCard_FAQ_NeedProfileToBuy_Text: "No, you do not need to have a profile to buy a gift card for Reshopper. However, the recipient of the gift card must have a profile to be able to use the gift card in the Reshopper app",
			GiftCard_FAQ_TermsOfUse_Title: "What are the terms of use for the gift card?",
			GiftCard_FAQ_TermsOfUse_Text: "A Reshopper gift card can be used for the purchase of second-hand items in trades between individuals, when you use Reshopper Freight in our Reshopper app as well as the purchase of surplus items in our Reshopper app. The gift card cannot currently be used to buy digital products such as Credits and Plus Family subscriptions. Read more on our <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link1}}\"}>terms of use here</a> and <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link2}}\"}>trade terms here</a>.",
			GiftCard_FAQ_NoEmailReceived_Title: "I didn't receive an e-mail after I bought a gift card?",
			GiftCard_FAQ_NoEmailReceived_Text: "You usually receive the e-mail and the gift card code within a short time, but give it just 5 minutes. If you still do not receive our e-mail, it is usually due to spam filters eating our e-mail, which primarily happens when we send to @hotmail and @live addresses (we recommend using a more stable e-mail provider, e.g. @gmail ). Need more help please write to <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>support</a>.",
			GiftCard_Form_Title: "You have chosen to purchase this gift card",
			GiftCard_Form_Text: "Fill in the fields* and have the gift card sent to your e-mail immediately.",
			GiftCard_Form_Text_NoLogin: "Purchase of gift cards does not require login.",
			GiftCard_Form_AlreadyUser: "Already a user? Log in here and we will automatically fill in quickly and easily.",
			GiftCard_Form_EnterAnAmount_Label: "Enter an amount",
			GiftCard_Form_EnterAnAmount_HelperText: "Min: DKK 100, Max: DKK 2500",
			GiftCard_Form_FirstName_Label: "First name",
			GiftCard_Form_LastName_Label: "Last name",
			GiftCard_Form_Email_HelperText: "NOTE: The gift card is sent to the specified e-mail",
			GiftCard_Form_Terms_Label: "I accept the <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>terms and conditions</a> and the payment obligation when ordering.",
			GiftCard_Form_Btn: "Checkout",
			GiftCard_Email_Validation_Invalid_Title: "Invalid email",
			GiftCard_Email_Validation_Invalid_Text: "The e-mail entered is invalid!<br />Please enter a valid e-mail.",
			GiftCard_Email_Validation_Suggestion_Title: "Confirm e-mail address is entered correctly",
			GiftCard_Email_Validation_Suggestion_Text: "Are you sure the e-mail you entered is correct?<br /><span style={{ display: \"block\", margin: \"8px 0\" }}><b>{{email}}</b></span><br/>Or did you mean {{suggestion}}?<br />",
			GiftCard_Email_Validation_Suggestion_Btn_Back: "Back",
			GiftCard_Email_Validation_Suggestion_Btn_Confirm: "The e-mail is correct",

			GiftCard_SuccessPage_PleaseWait: "Please wait..",
			GiftCard_SuccessPage_Error_Title: "There is an error.",
			GiftCard_SuccessPage_Error_Text: "Please contact <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>support</a>.",
			GiftCard_SuccessPage_Success_Title: "Thank you very much for choosing to buy a gift card for Reshopper🙏♻️",
			GiftCard_SuccessPage_Success_Text: "The gift card has been sent to your <span>e-mail.</span>",
			GiftCard_SuccessPage_Success_Box_Headline: "How to pass on the gift card to someone you care about❤️",
			GiftCard_SuccessPage_Success_Box_Text: "Forward the <span>email</span> with gift card and code or print the attached PDF in the <span>email</span> and fold the gift card to fit in an envelope.",
			GiftCard_SuccessPage_Success_Text_ReadMore: "Read more at <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link1}}\"}>reshopper.com/gavekort</a> or contact our <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link2}}\"}>support</a>.",

			Plus_Signup_Family_Subheadline: "- for an easier, cheaper and more circular family life!",
			Plus_Signup_Family_SignUpButton: "Sign up",
			Plus_Signup_Family_SignUpButton_ExtraText: "No binding. The subscription is renewed automatically.",
			Plus_Signup_Family_AllBenefits_Title: "All benefits",
			Plus_Signup_Family_FAQ_Title: "How does Plus Family work?",
			Plus_Signup_Family_LowerShippingPrices_Subheader: "Always cheaper shipping with GLS, dao and instabox.",
			Plus_Signup_Family_ServiceFeeSaving_Subheader: "Sell ​​for free and save 25% on buyer protection",
			Plus_Signup_Family_FeatureCreditsPerMont_Subheader: "Boost your items with credits and increase the chance of faster sales. Value DKK 39.",
			Plus_Signup_Family_RetailSavings_Header: "-40% on surplus items",
			Plus_Signup_Family_RetailSavings_Subheader: "If you buy for just DKK 100 per month, your membership is earned.",
			Plus_Signup_Family_LowerShippingPrices_Title: "Cheaper shipping",
			Plus_Signup_Family_LowerShippingPrices_Text: "Always cheaper shipping with GLS, dao and instabox.<br />The savings depend on whether you choose GLS, dao or instabox.<br />See our prices for members and non-members <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>here</a>.",
			Plus_Signup_Family_RetailSavings_Title: "Save 40% on surplus items",
			Plus_Signup_Family_RetailSavings_Text: "Always save at least 40% on all our surplus items in the app¹.<br />This means that if you simply buy clothes and toys for DKK 100 per month in our app, the subscription is earned and all the other benefits are \"free\"!",
			Plus_Signup_Family_FeatureCreditsPerMont_Title: "30 credits every month",
			Plus_Signup_Family_FeatureCreditsPerMont_Text: "Get 30 credits every month (normal price: DKK 39)².<br />These credits can be used to boost your items and improve their visibility, so you can make even more good deals - again and again.",
			Plus_Signup_Family_ServiceFeeSaving_Title: "Sell ​​for free on Reshopper",
			Plus_Signup_Family_ServiceFeeSaving_Text: "We've also reduced our trading costs on our Seller Protection and <a rel=\"noopener noreferrer\" href=\"{{link}}\"}>Buyer Protection</a> by 25% for our Reshopper Plus Family members, so your profit from selling the used treasures will be even greater.<br/>Non-members pay DKK 1.5 + 1.5% of the transaction price in seller protection, and members pay DKK 0 in seller protection.",
			Plus_Signup_Family_NoAdsInApp_Title: "No ads in app",
			Plus_Signup_Family_NoAdsInApp_Text: "As a member of the \"family\", you will of course also get an experience completely without banner ads from external advertisers.",
			Plus_Signup_Family_FeaturePrioritizedItems_Title: "Search prioritization",
			Plus_Signup_Family_FeaturePrioritizedItems_Text: "As a Plus Family member, your items will be prioritized higher in search results than non-members' items (a bit like getting on Google's page 1). In this way, your chance of a sale increases.",
			Plus_Signup_Family_Credibility_Title: "You gain increased credibility",
			Plus_Signup_Family_Credibility_Text: "When you are a Reshopper Plus Family member, you also get a plus sticker on your profile so that others can also see that you are a Plus member.",
			Plus_Signup_Family_ResaleService_Title: "We sell the children's clothes for you",
			Plus_Signup_Family_ResaleService_Text: "New exclusive service (coming soon): Drop off the children's clothing in one of our stores, then we will put the item up for sale and you can simply sit back and receive the money when the item is sold.",
			Plus_Signup_Family_Terms_One: "¹Only applies to Reshopper's surplus items. Does not apply to items sold by private users. The discount cannot be combined with other offers and promotions - you always get the cheapest price.",
			Plus_Signup_Family_Terms_Two: "²Credits are awarded upon payment.",
			Plus_Signup_Family_Help_Title_Member: "How do I become a member?",
			Plus_Signup_Family_Help_Text_Member: "You can register here.",
			Plus_Signup_Family_Help_Title_MemberPricer: "What does the membership cost?",
			Plus_Signup_Family_Help_Text_MemberPricer: "For only DKK 39 per month, you get access to an easier, cheaper and more circular family life! The subscription is automatically renewed every month. You can unsubscribe again at any time. So no binding.",
			Plus_Signup_Family_Help_Title_BindingPeriod: "What does no binding mean?",
			Plus_Signup_Family_Help_Text_BindingPeriod: "It simply means that you can unsubscribe from Plus Family at any time, because we have no binding period. This means no binding. You decide.",
			Plus_Signup_Family_Help_Title_CancelPlus: "How do I cancel?",
			Plus_Signup_Family_Help_Text_CancelPlus: "Open the app and tap \"You\", then \"Settings\". Select \"Account\" and scroll down to find the option to cancel your Plus subscription. If you experience problems, write immediately to <a href=\"mailto:{{mail}}\">support@reshopper.com</a>, we will respond as soon as possible.",
			Plus_Signup_Family_Error_Title_SubscriptionIssue: "There is a subscription error for your user - contact support before proceeding",
			Plus_Signup_Business_PaymentSystem_Text: "Integrated and secure payment system.",
			Plus_Signup_Business_Shipping_Text: "Integrated freight solution with Reshopper Freight.",
			Plus_Signup_Business_AutoPayment_Text: "Automatic weekly payout via bank transfer.",
			Plus_Signup_Business_Fee_Text: "3.5% fee on sales*.",
			Plus_Signup_Business_Credits_Text: "Get 20 credits every month (normal price: DKK 36).",
			Plus_Signup_Content_AdditionalBusinessTerms: "*Minimum 2 kr for reg. offer, and 4 kr for offer with delivery.",

			BuyerProtection_Title: "Buyer protection",
			BuyerProtection_Text: "All trades made with Reshopper's secure trading system are protected! Reshopper's buyer protection includes: Up to 100% fraud and discrepancy coverage, IT fraud protection, extended support and much more. To make all these buyer protection benefits possible {{priceForMembers}} + {{percentageForMembers}}% of the trade price is added per trading with Reshopper's secure trading system for Plus members and {{priceForNonMembers}} + {{percentageForNonMembers}}% for non-members. With Buyer Protection, our users can shop with confidence and peace of mind, knowing their purchases are protected. This helps to create an even safer environment on the platform.",
			BuyerProtection_Label: "New",
			BuyerProtection_Benefit_One_Titel: "Up to 100% cover with fraud",
			BuyerProtection_Benefit_One_Text: "If there has been fraud or discrepancies in a transaction, and it cannot be resolved between buyer and seller, then Reshopper will intervene in the case. If the buyer is approved, Reshopper covers up to 100% of the item's value, but a maximum of DKK 10,000.",
			BuyerProtection_Benefit_Two_Titel: "Protection against IT fraud",
			BuyerProtection_Benefit_Two_Text: "Protection against IT fraud includes thorough verification of both seller and buyer through SMS validation of Danish mobile numbers and MitID verification. Payments to sellers are handled manually to avoid payments to suspicious accounts. In addition, we monitor atypical behavior as an active prevention of IT fraud.",
			BuyerProtection_Benefit_Three_Titel: "Delivery guarantee",
			BuyerProtection_Benefit_Three_Text: "All shipments are insured up to DKK 2,500-4,500, depending on which freight supplier is used. You just need to contact us, after which we will contact the freight supplier in question to have them replace the package. The insurance sum paid out is based on the traded value of the item.",
			BuyerProtection_Benefit_Four_Titel: "Secure integrated payment",
			BuyerProtection_Benefit_Four_Text: "Secure payment is made via Reshopper's trading system either by delivery or private collection. The amount from the buyer is raised, either when the buyer has collected privately or when the package is handed in by the seller. 3 days later, Reshopper has the money, which is transferred to the seller on the same day. This gives the buyer the advantage that we catch potential fraudsters more easily, as the package is usually delivered by then.",
			BuyerProtection_Benefit_Five_Titel: "Extended support",
			BuyerProtection_Benefit_Five_Text: "Britt and Clara are ready to help you! Our extended support includes help with technical problems and active participation in the dialogue in case of any disagreements between buyer and seller. We are dedicated to ensuring a seamless experience for all parties involved.",
			BuyerProtection_Benefit_Six_Titel: "Buyer protection",
			BuyerProtection_Benefit_Six_Text: "The buyer protection contains all of the above and is essential for us to offer an even safer and better experience for buyers on our platform. With buyer protection, our customers can shop with confidence and peace of mind knowing their purchase is protected. This helps to create an even safer environment on the platform.",
			BuyerProtection_Faq_Title: "Frequently asked questions",
			BuyerProtection_Faq_One_Title: "How does it work?",
			BuyerProtection_Faq_One_Text: "The buyer protection contains several advantages for the buyer in a trade in second-hand goods: Reshopper ensures up to 100% coverage of the purchase amount in the event of fraud (max. DKK 1,000), protects against IT fraud, provides a delivery guarantee, provides extended support and much more. This means that if you as a buyer have a problem with his trade, then Reshopper will step in and help get it resolved. In order to provide this service and protect all buyers, the transaction is charged {{priceForMembers}} + {{percentageForMembers}}% of the trade price for Plus-members and {{priceForNonMembers}} + {{percentageForNonMembers}}% of the trade price for non-members. The buyer must see if the matter can be resolved with the seller, and a minimum of 3 pictures of the problem with the item must be submitted for Reshopper to assess the matter.",
			BuyerProtection_Faq_Two_Title: "Am I always covered by buyer protection?",
			BuyerProtection_Faq_Two_Text: "All trades with Reshopper's trading system are automatically covered by buyer protection. Transactions that take place outside of Reshopper and where payment is made in cash or with Mobilepay are not covered by buyer protection.",
			BuyerProtection_Faq_Three_Title: "I received the product in worse condition than described. What do I do?",
			BuyerProtection_Faq_Three_Text: "If you receive or have collected an item that you believe is in a different condition than described and the transaction is protected by Reshopper's Buyer Protection, you must first contact the seller and see if you can resolve the dispute. If not, click the buyer protection button, describe the problem and Submit 3 photos to our support team and we will guide you through the process of resolving the issue and assessing your eligibility for compensation.",
			BuyerProtection_Faq_Four_Title: "Is my information safe with Reshopper?",
			BuyerProtection_Faq_Four_Text: "At Reshopper, we have a very strong focus on keeping out shady types. It is, among other things, therefore, we have MitID verification and SMS validation of Danish mobile numbers. We make a great effort to monitor atypical and suspicious behavior and actively prevent attempts at IT fraud and phishing. You can therefore feel completely safe when you create a user and trade with others on the platform. The buyer protection also means that Reshopper can cover up to 100% of your purchase if disagreements arise with a seller (however max. DKK 1,000).",
			BuyerProtection_Faq_Five_Title: "The item I ordered has not arrived, what do I do?",
			BuyerProtection_Faq_Five_Text: "If your package does not arrive within the normal delivery time, you can contact our support by pressing 'help' at the bottom of the transaction. When you do, our support will receive a message and get back to you by email.",
			BuyerProtection_Faq_Six_Title: "What does secure payment mean?",
			BuyerProtection_Faq_Six_Text: "Secure payment is made via Reshopper's trading system either by delivery or private collection. Here, the amount is collected from the buyer, either when the buyer presses 'PAY' after collection, or when the package is handed in by the seller. 3 business days later, Reshopper has the money, which is transferred to the seller on the same day. In the case of shipping, it gives the buyer the advantage that we catch potential fraudsters more easily, as the package is usually delivered by then.",
			BuyerProtection_Faq_Seven_Title: "What does extended support mean?",
			BuyerProtection_Faq_Seven_Text: "Our support team is ready to help both with technical problems and if there are disagreements between buyer and seller. With buyer protection, it will now also be possible for support to assess the case and compensate the buyer if fraud has taken place.",
			BuyerProtection_Faq_Eight_Title: "Is there any documentation I need to provide to claim Buyer Protection?",
			BuyerProtection_Faq_Eight_Text: "In order for Reshopper to assess the case, a minimum of 3 photos of the item that the discrepancy is about must be submitted, regardless of whether the transaction was made via freight or private collection. Our support team can ask for additional documentation.",
			BuyerProtection_Faq_Nine_Title: "What do I do if I find a fault with the item later than 3 days after receipt or collection?",
			BuyerProtection_Faq_Nine_Text: "When you receive or pick up the item, you should look through it to ascertain whether everything is as it should be. If several days pass, it can become difficult for Reshopper to assess whether the stand has changed because the item has been put into use or because it was different from what was described. If more than 3 days have passed, you must contact the seller and see if you can find a solution together.",

			LogIn_Text: "Log in",
			LogIn_Email: "Email",
			LogIn_Password: "Password",
			LogIn_Error: "Login failed. Please try again.",
			New_User_Text: "New user?",
			LinkText_Download_App: "Download our app here",
			LinkText_Go_To_Frontpage: "Go to frontpage",
			ButtonText_Download_App: "Download app",
			See_More_Text: "See more",
			See_Less_Text: "See less",

			Credits_Content_InfoText: "Use credits to boost your items, and achieve faster sales.",
			Credits_Banner_TitleText1: "Get",
			Credits_Banner_TitleText2: "<span>with</span> Reshopper",
			Credits_Banner_Text: "In addition, you get a 40% discount on all surplus items, cheaper shipping, a 25% discount on buyer protection, search priority and no advertising",
			Credits_Banner_Btn: "Read more",
			Confirmation_Credits_SuccesMessageTitle: "Done!",
			Confirmation_Credits_SuccesMessage: "Your credits are on your account.<br/>You can now return to the app 🙂",

			Account_Reset_Password_Title: "Change password",
			Account_Reset_Password_Hint: "New password",
			Account_Reset_Password_NoPasswordSpecified: "No password specified.",
			Account_Reset_Password_LinkExpired: "The password link has expired.",
			Account_Reset_Password_PasswordMustBeAtLeastCharacters: "Password must be at least {{length}} characters.",
			Account_Reset_Password_Update: "Save",
			Information_Card_Payment_On_File: "PSA - We already know your card details, so the only thing you need to enter is the 3 digit card security code on the back of your card 🙂 \n \n PSA: You can add a new card by clicking: \"You\"  → \"Settings\" → \"Account\"",

			Web_Dialog_Confirm: "Confirm",
			Web_Dialog_Cancel: "Cancel",

			Update_Available_Header: "Time for an update",
			Update_Available_Subheader: "Oops, it looks like your Reshopper app could use an update.",
			Update_Available_Text: "It would be a good idea to update the app, if you want to have the best possible Reshopper experience and access all of our new features. It will only take a second.",
			Update_Available_ButtonText: "Update now",
			Update_Available_AndroidText: "Find the update in the Play Store",

			Receipts_NotFound: "Unfortunately, we found no receipt on this link.",
			Payment_Error_Toast: "A payment error has occured: {{error}}"
		}
	},
	da: {
		translation: {
			Plus_Signup_Header_NoCommitmentCancelAnytime: "Ingen binding og ingen reklamer.",
			Plus_Signup_Header_SubscriptionWillRenewAutomatically: "Abonnementet fornyes automatisk.",
			Plus_Signup_Footer_Contact: "Kontakt",
			Plus_Signup_Content_Text: "Vil du opgradere til {{planName}}?",
			Plus_Signup_Content_YouAreAMember: "Nuværende plan",
			Plus_Signup_Content_ShortYear: "år",
			Plus_Signup_Content_ShortMonth: "md",
			Plus_Signup_Content_SignupNow: "Tilmeld nu",
			Plus_Signup_Content_UpgradeButton: "Opgrader",
			Plus_Signup_Content_UpgradeText: "ved opgradering skifter din Plus status med det samme, og prisforskellen for den resterende periode betales med det samme",
			Plus_Signup_Confirmation_SuccesMessageTitle: "Tillykke!",
			Plus_Signup_Confirmation_SuccesMessage: "Du er nu Plus medlem og kan vende tilbage til appen. 🙂",

			GiftCard: "Gavekort",
			GiftCard_Btn_Buy: "Køb",
			GiftCard_Btn_CustomAmount: "Vælg beløb",
			GiftCard_Text_Custom: "Vælg",
			GiftCard_Text: "Et Reshopper gavekort vil glæde enhver børnefamilie til hvilken som helst lejlighed - eks. barnedåb, babyshower, fødselsdag eller julegave. Gavekortet kan bruges til køb af secondhand-varer fra andre familier og nye overskudsvarer i vores app.",
			GiftCard_FAQ_Title: "Et meningsfuldt gavekort",
			GiftCard_FAQ_Text: "Med et gavekort fra Reshopper kan du give en meningsfuld gave, der glæder både børnefamilien, miljøet og pengepungen 🤩♻️💰<br /><br />Reshopper er Danmarks største markedsplads for børnefamilier, hvor du kan købe og sælge secondhand varer til børn, mor og bolig. Børnefamilierne opretter mere end 300.000 nye secondhand varer hver måned. Udover secondhand varer har Reshopper også et mindre sortiment af nye overskudsvarer fra restpartier og tidligere kollektioner, hvor man altid sparer minimum 50%. Derfor rækker pengene virkelig langt hos Reshopper.",
			GiftCard_FAQ_WhereToBuyGiftCard_Title: "Hvor køber jeg et gavekort?",
			GiftCard_FAQ_WhereToBuyGiftCard_Text: "Gavekort kan lige nu kun købes online på Reshopper.com/gavekort.",
			GiftCard_FAQ_HowLongIsGiftCardValidFor_Title: "Hvor længe gælder et gavekort?",
			GiftCard_FAQ_HowLongIsGiftCardValidFor_Text: "Gavekortet er gyldigt i 1 år fra udstedelsesdatoen. Udstedelsesdatoen vil være synlig på gavekortet.",
			GiftCard_FAQ_HowIsGiftCardReceived_Title: "Hvordan modtages gavekortet?",
			GiftCard_FAQ_HowIsGiftCardReceived_Text: "Gavekortet er elektronisk og bliver sendt via mail.<br />Når du køber et gavekort online bliver det udstedt i elektronisk form og du får tilsendt en digital gavekort kode til din e-mail, som du kan printe ud eller videresende.",
			GiftCard_FAQ_WhereCanGiftCardBeUsed_Title: "Hvor og hvordan kan gavekortet bruges?",
			GiftCard_FAQ_WhereCanGiftCardBeUsed_Text: "Gavekortet kan bruges til køb af nye overskudsvarer og secondhand-varer fra andre familier i vores app, når du køber med Reshopper Fragt (kan ikke bruges til en lokal handel endnu).<br/>Når du anvender gavekortet i en privat handel med Reshopper Fragt i appen, reserveres pengene, når buddet afgives. Overstiger købsbeløbet gavekortets værdi, kan det kombineres med andre betalingsmetoder.",
			GiftCard_FAQ_NeedProfileToBuy_Title: "Skal jeg have en profil på Reshopper for at købe et gavekort?",
			GiftCard_FAQ_NeedProfileToBuy_Text: "Nej, du behøver ikke at have en profil for at købe et gavekort til Reshopper. Dog skal modtageren af gavekortet have en profil for at kunne benytte gavekortet i Reshopper appen.",
			GiftCard_FAQ_TermsOfUse_Title: "Hvad er brugervilkår for gavekortet?",
			GiftCard_FAQ_TermsOfUse_Text: "Et Reshopper gavekort kan bruges til køb af secondhand-varer i handler mellem private, når man benytter Reshopper Fragt i vores Reshopper app samt køb af overskudsvarer i vores Reshopper app. Gavekortet kan lige nu ikke bruges til at købe digitale produkter som Credits og Plus Family abonnement. Læs mere på vores <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link1}}\"}>brugervilkår her</a> og <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link2}}\"}>handelsbetingelser her</a>.",
			GiftCard_FAQ_NoEmailReceived_Title: "Jeg har ikke modtaget en e-mail efter jeg har købt et gavekort?",
			GiftCard_FAQ_NoEmailReceived_Text: "Du modtager som regel e-mailen og gavekort-koden indenfor kort tid, men giv den lige 5 minutter. Modtager du fortsat ikke vores e-mail skyldes det som regel, at spamfiltre æder vores e-mail, hvilket primært sker når vi sender til @hotmail og @live adresser (vi anbefaler at bruge en mere stabil e-mail udbyder, fx @gmail). Brug for mere hjælp skriv gerne til <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>support</a>.",
			GiftCard_Form_Title: "Du har valgt køb af dette gavekort",
			GiftCard_Form_Text: "Udfyld felterne* og få gavekortet tilsendt din e-mail med det samme.",
			GiftCard_Form_Text_NoLogin: "Køb af gavekort kræver ikke login. ",
			GiftCard_Form_AlreadyUser: "Allerede bruger? Log ind her og vi udfylder automatisk hurtig og nemt.",
			GiftCard_Form_EnterAnAmount_Label: "Angiv et beløb",
			GiftCard_Form_EnterAnAmount_HelperText: "Min: 100 DKK, Maks: 2500 DKK",
			GiftCard_Form_FirstName_Label: "Fornavn",
			GiftCard_Form_LastName_Label: "Efternavn",
			GiftCard_Form_Email_HelperText: "OBS: Gavekortet sendes til den angivne e-mail",
			GiftCard_Form_Terms_Label: "Jeg accepterer <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>handelsbetingelserne</a> samt betalingsforpligtigelsen ved bestilling.",
			GiftCard_Form_Btn: "Gå til betaling",
			GiftCard_Email_Validation_Invalid_Title: "Ugyldig e-mail",
			GiftCard_Email_Validation_Invalid_Text: "Den indtastede e-mail er ugyldig!<br />Indtast ventligst en gyldig e-mail.",
			GiftCard_Email_Validation_Suggestion_Title: "Bekræft e-mailadresse er korrekt indtastet",
			GiftCard_Email_Validation_Suggestion_Text: "Er du sikker på, at den indtastede e-mail er korrekt?<br /><span style={{ display: \"block\", margin: \"8px 0\" }}><b>{{email}}</b></span><br/>Eller mente du {{suggestion}}?<br />",
			GiftCard_Email_Validation_Suggestion_Btn_Back: "Tilbage",
			GiftCard_Email_Validation_Suggestion_Btn_Confirm: "E-mailen er korrekt",

			GiftCard_SuccessPage_PleaseWait: "Vent venligst..",
			GiftCard_SuccessPage_Error_Title: "Der er opstået en fejl.",
			GiftCard_SuccessPage_Error_Text: "Kontakt venligst <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>support</a>.",
			GiftCard_SuccessPage_Success_Title: "Mange tak, fordi du valgte at købe et gavekort til Reshopper🙏♻️",
			GiftCard_SuccessPage_Success_Text: "Gavekortet er sendt afsted til din <span>e-mail.</span>",
			GiftCard_SuccessPage_Success_Box_Headline: "Sådan giver du gavekortet videre til én du holder af❤️",
			GiftCard_SuccessPage_Success_Box_Text: "Videresend <span>e-mailen</span> med gavekort og kode eller print den vedhæftede PDF i <span>e-mailen</span> og fold gavekortet, så det passer i en konvolut.",
			GiftCard_SuccessPage_Success_Text_ReadMore: "Læs mere på <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link1}}\"}>reshopper.com/gavekort</a> eller kontakt vores <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link2}}\"}>support</a>.",

			Plus_Signup_Family_Subheadline: "- for et nemmere, billigere og mere cirkulært familieliv!",
			Plus_Signup_Family_SignUpButton: "Bliv medlem",
			Plus_Signup_Family_SignUpButton_ExtraText: "Ingen binding. Abonnementet fornyes automatisk.",
			Plus_Signup_Family_AllBenefits_Title: "Alle fordele",
			Plus_Signup_Family_FAQ_Title: "Hvordan fungerer Plus Family?",
			Plus_Signup_Family_LowerShippingPrices_Subheader: "Altid billigere fragt med GLS, dao og instabox.",
			Plus_Signup_Family_ServiceFeeSaving_Subheader: "Sælg gratis og spar 25% på køberbeskyttelse.",
			Plus_Signup_Family_FeatureCreditsPerMont_Subheader: "Boost dine varer med credits og øg chancen for hurtigere salg. Værdi 39 kr.",
			Plus_Signup_Family_RetailSavings_Header: "-40% på overskudsvarer",
			Plus_Signup_Family_RetailSavings_Subheader: "Køber du blot for 100 kr om måneden er dit medlemskab tjent hjem.",
			Plus_Signup_Family_LowerShippingPrices_Title: "Billigere fragt",
			Plus_Signup_Family_LowerShippingPrices_Text: "Altid billigere fragt med GLS, dao og instabox.<br />Besparelsen afhænger af om du vælger GLS, dao eller instabox.<br />Se vores priser for medlemmer og ikke-medlemmer <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{link}}\"}>her</a>.",
			Plus_Signup_Family_RetailSavings_Title: "Spar 40% på overskudsvarer",
			Plus_Signup_Family_RetailSavings_Text: "Spar altid mindst 40% på alle vores overskudsvarer i app¹.<br />Det vil sige, at hvis du blot køber tøj og legetøj for 100 kroner om måneden i vores app, så er abonnementet tjent hjem og alle de andre fordele er \"gratis\"!",
			Plus_Signup_Family_FeatureCreditsPerMont_Title: "30 credits hver måned",
			Plus_Signup_Family_FeatureCreditsPerMont_Text: "Få 30 credits hver måned (normalpris: 39 DKK)².<br />Disse credits kan bruges til at booste dine varer og forbedre deres synlighed, så du kan lave endnu flere gode handler - igen og igen.",
			Plus_Signup_Family_ServiceFeeSaving_Title: "Sælg gratis på Reshopper",
			Plus_Signup_Family_ServiceFeeSaving_Text: "Vi har også reduceret vores handelsomkostninger på vores sælgerbeskyttelse og <a rel=\"noopener noreferrer\" href=\"{{link}}\"}>køberbeskyttelse</a> med 25% for vores Reshopper Plus Family-medlemmer, så din fortjeneste på at sælge de brugte skatte bliver endnu større.<br/>Ikke-medlemmer betaler 1,5 DKK + 1,5% af handlens pris i sælgerbeskyttelse, og medlemmer betaler 0 DKK i sælgerbeskyttelse.",
			Plus_Signup_Family_NoAdsInApp_Title: "Ingen reklamer i app",
			Plus_Signup_Family_NoAdsInApp_Text: "Som medlem af “familien” får du selvfølgelig også en oplevelse helt uden bannerannoncer fra eksterne annoncører.",
			Plus_Signup_Family_FeaturePrioritizedItems_Title: "Søgeprioritering",
			Plus_Signup_Family_FeaturePrioritizedItems_Text: "Som Plus Family medlem vil dine varer blive prioriteret højere i søgeresultater, end ikke-medlemmers varer (lidt som at komme på Googles side 1). På den måde øges din chance for salg.",
			Plus_Signup_Family_Credibility_Title: "Du får øget troværdighed",
			Plus_Signup_Family_Credibility_Text: "Når du er Reshopper Plus Family medlem, så får du desuden et plus-mærkat på din profil, så andre også kan se, at du er Plus-medlem.",
			Plus_Signup_Family_ResaleService_Title: "Vi sælger børnetøjet for dig",
			Plus_Signup_Family_ResaleService_Text: "Ny eksklusiv service (kommer snart): Aflever børnetøjet i en af vores butikker, så sætter vi varen til salg og du kan blot læne dig tilbage og modtage pengene når varen er solgt.",
			Plus_Signup_Family_Terms_One: "¹Gælder kun Reshoppers overskudsvarer. Gælder ikke varer solgt af private brugere. Rabatten kan ikke kombineres med andre tilbud og kampagner - du får altid den billigste pris.",
			Plus_Signup_Family_Terms_Two: "²Credits tildeles ved betaling.",
			Plus_Signup_Family_Help_Title_Member: "Hvordan bliver jeg medlem?",
			Plus_Signup_Family_Help_Text_Member: "Du kan tilmelde dig her.",
			Plus_Signup_Family_Help_Title_MemberPricer: "Hvad koster medlemskabet?",
			Plus_Signup_Family_Help_Text_MemberPricer: "For kun 39 kr. om måneden får du adgang til et nemmere, billigere og mere cirkulært familieliv! Abonnementet fornyes automatisk hver måned. Du kan når som helst afmelde dig igen. Så ingen binding.",
			Plus_Signup_Family_Help_Title_BindingPeriod: "Hvad betyder ingen binding?",
			Plus_Signup_Family_Help_Text_BindingPeriod: "Det betyder blot, at du kan afmelde dig Plus Family til enhver tid, fordi vi ikke har nogen bindingsperiode. Det vil altså sige ingen binding. Du bestemmer selv.",
			Plus_Signup_Family_Help_Title_CancelPlus: "Hvordan opsiger jeg?",
			Plus_Signup_Family_Help_Text_CancelPlus: "Åbn appen og tryk på \"Dig\", og herefter \"Indstillinger\". Vælg \"Konto\" og scroll ned for at finde muligheden for at opsige dit Plus abonnement. Oplever du problemer skriv med det samme til <a href=\"mailto:{{mail}}\">support@reshopper.com</a>, vi skal nok svare hurtigst muligt.",
			Plus_Signup_Family_Error_Title_SubscriptionIssue: "Der er en fejl med abonnement for din bruger - kontakt support inden du fortsætter",
			Plus_Signup_Business_PaymentSystem_Text: "Integreret og sikkert betalingssystem.",
			Plus_Signup_Business_Shipping_Text: "Integreret fragtløsning med Reshopper Fragt.",
			Plus_Signup_Business_AutoPayment_Text: "Automatisk ugentlig udbetaling via bankoverførsel.",
			Plus_Signup_Business_Fee_Text: "3,5% gebyr på salg*.",
			Plus_Signup_Business_Credits_Text: "Få 20 credits hver måned (normalpris: 36 DKK).",
			Plus_Signup_Content_AdditionalBusinessTerms: "*Minimum 2 kr for alm. bud, og 4 kr for bud med fragt.",

			BuyerProtection_Title: "Køberbeskyttelse",
			BuyerProtection_Text: "Alle handler der er foretaget med Reshoppers sikre handelssystem er beskyttet! Reshoppers køberbeskyttelse indeholder: Op til 100% dækning ved svindel og uoverensstemmelser, beskyttelse mod it-svindel, udvidet support og meget mere. For at gøre alle disse fordele i køberbeskyttelsen mulige tillægges {{priceForMembers}} + {{percentageForMembers}}% af handlens pris pr. handel med Reshoppers sikre handelssystem for Plus-medlemmer og {{priceForNonMembers}} + {{percentageForNonMembers}}% af handlens pris for ikke medlemmer. Med køberbeskyttelse kan vores brugere handle med tillid og ro i sindet, velvidende om, at deres køb er beskyttet. Dette er med til at skabe et endnu mere trygt miljø på platformen.",
			BuyerProtection_Label: "Nyhed",
			BuyerProtection_Benefit_One_Titel: "Op til 100% dækning med svindel",
			BuyerProtection_Benefit_One_Text: "Hvis der er foregået svindel eller uoverensstemmelser i en handel, og det ikke kan løses mellem køber og sælger, så vil Reshopper gå ind i sagen. Hvis der gives medhold til køber, dækker Reshopper op til 100% af	varens værdi, dog maks. 10.000 DKK.",
			BuyerProtection_Benefit_Two_Titel: "Beskyttelse mod it-svindel",
			BuyerProtection_Benefit_Two_Text: "Beskyttelse mod it-svindel inkluderer grundig verificering af både sælger og køber gennem SMS-validering af danske mobilnumre og MitID-verifikation. Udbetalinger til sælgere håndteres manuelt for at undgå udbetalinger til mistænkelige konti. Derudover overvåger vi atypisk adfærd som aktiv forhindring af it-svindel.",
			BuyerProtection_Benefit_Three_Titel: "Leveringsgaranti",
			BuyerProtection_Benefit_Three_Text: "Alle forsendelser er forsikret op til 2.500-4.500 DKK., alt efter hvilken fragtleverandør der benyttes. Du skal blot kontakte os, hvorefter vi kontakter den pågældende fragtleverandør med henblik på, at de erstatter pakken. Den udbetalte forsikringssum baseres på den handlede værdi af varen.",
			BuyerProtection_Benefit_Four_Titel: "Sikker integreret betaling",
			BuyerProtection_Benefit_Four_Text: "Sikker betaling sker via Reshoppers handelssystem enten ved fragt eller privat afhentning. Beløbet fra køber hæves, enten når køber har afhentet privat eller når pakken indleveres af sælger. 3 dage derefter har Reshopper pengene, som overføres videre til sælger samme dag. Det giver køber den fordel, at vi nemmere fanger eventuelle svindlere, da pakken som regel er fremme inden da.",
			BuyerProtection_Benefit_Five_Titel: "Udvidet support",
			BuyerProtection_Benefit_Five_Text: "Britt og Clara sidder klar til at hjælpe dig! Vores udvidet support omfatter hjælp til tekniske problemer og aktiv deltagelse i dialogen ved eventuelle uoverensstemmelser mellem køber og sælger. Vi er dedikerede til at sikre en problemfri oplevelse for alle involverede parter.",
			BuyerProtection_Benefit_Six_Titel: "Køberbeskyttelse",
			BuyerProtection_Benefit_Six_Text: "Køberbeskyttelsen indeholder alt ovenstående og er afgørende for, at vi kan tilbyde en endnu mere sikker og god oplevelse for købere på vores platform. Med køberbeskyttelse kan vores kunder handle med tillid og ro i sindet velvidende om, at deres køb er beskyttet. Dette er med til at skabe et endnu mere trygt miljø på platformen.",
			BuyerProtection_Faq_Title: "Ofte stillede spørgsmål",
			BuyerProtection_Faq_One_Title: "Hvordan virker det?",
			BuyerProtection_Faq_One_Text: "Køberbeskyttelsen indeholder flere fordele for køber i en handel med secondhand-varer: Reshopper sikrer op til 100% dækning af købsbeløbet i tilfælde af svindel (maks 1.000 DKK), beskytter mod it-svindel, giver leveringsgaranti, giver udvidet support og meget mere. Der betyder, at hvis man som køber har et problem ifbm. sin handel, så vil Reshopper gå ind i sagen og hjælpe med at få det løst. For at kunne yde denne service og beskytte alle købere tillægges handlen {{priceForMembers}} + {{percentageForMembers}}% af handlens pris for Plus-medlemmer og {{priceForNonMembers}} + {{percentageForNonMembers}}% af handlens pris for ikke-medlemmer. Køber skal se, om sagen kan løses med sælger, og der skal indsendes minimum 3 billeder af problemet med varen for at Reshopper kan vurdere sagen.",
			BuyerProtection_Faq_Two_Title: "Er jeg altid dækket af køberbeskyttelsen?",
			BuyerProtection_Faq_Two_Text: "Alle handler med Reshoppers handelssystem er automatisk dækket af køberbeskyttelsen. Handler, der foregår udenom Reshopper og hvor betaling sker kontant eller med Mobilepay, er ikke dækket af køberbeskyttelsen.",
			BuyerProtection_Faq_Three_Title: "Jeg har modtaget vare i dårligere stand end beskrevet. Hvad gør jeg?",
			BuyerProtection_Faq_Three_Text: "Hvis du modtager eller har afhentet en vare, som du mener er i en anden stand end beskrevet, og handlen er beskyttet af Reshoppers Køberbeskyttelse, skal du først kontakte sælger og se om I kan løse uenigheden. Hvis ikke skal du klikke på køberbeskyttelsesknappen, beskrive problemet og indsende 3 billeder til vores supportteam. Vi vil guide dig gennem processen med at løse problemet og vurdere din berettigelse til kompensation.",
			BuyerProtection_Faq_Four_Title: "Er mine oplysninger i sikkerhed hos Reshopper?",
			BuyerProtection_Faq_Four_Text: "I Reshopper har vi et meget stort fokus på at holde skumle typer ude. Det er bl.a. derfor, vi har MitID-verificering og SMS-validering af danske mobilnumre. Vi gør en stor indsats for at overvåge atypisk og mistænkelig adfærd og forhindrer aktivt forsøg på it-svindel og phishing. Du kan derfor føle dig helt tryg, når du opretter en bruger og handler med andre på platformen. Køberbeskyttelsen gør samtidig, at Reshopper kan dække op til 100% af dit køb, hvis der opstår uoverensstemmelser med en sælger (dog max. 1.000 DKK).",
			BuyerProtection_Faq_Five_Title: "Den vare jeg har bestilt er ikke kommet frem, hvad gør jeg?",
			BuyerProtection_Faq_Five_Text: "Hvis din pakke ikke kommer frem indenfor normal leveringstid, så kan du kontakte vores support ved at trykke på 'hjælp' nederst på handlen. Når du gør det, så får vores support en besked og vender tilbage til dig på mail.",
			BuyerProtection_Faq_Six_Title: "Hvad betyder sikker betaling?",
			BuyerProtection_Faq_Six_Text: "Sikker betaling sker via Reshoppers handelssystem enten ved fragt eller privat afhentning. Her hæves beløbet fra køber, enten når køber trykker 'BETAL' efter afhentning, eller når pakken indleveres af sælger. 3 hverdage derefter har Reshopper pengene, som overføres videre til sælger samme dag. Ved fragt giver det køber den fordel, at vi nemmere fanger eventuelle svindlere, da pakken som regel er fremme inden da.",
			BuyerProtection_Faq_Seven_Title: "Hvad betyder udvidet support?",
			BuyerProtection_Faq_Seven_Text: "Vores supportteam står både klar til at hjælpe med tekniske problemer, og hvis der er uoverensstemmelser mellem køber og sælger. Med køberbeskyttelsen bliver det nu også muligt for support at vurdere sagen og kompensere køber, hvis der er foregået svindel.",
			BuyerProtection_Faq_Eight_Title: "Er der nogen dokumentation, jeg skal fremlægge for at gøre krav på Køberbeskyttelsen?",
			BuyerProtection_Faq_Eight_Text: "For at Reshopper kan vurdere sagen skal der indsendes minimum 3 billeder af det, som uoverenstemmelsen handler om, uanset om handlen er sket via fragt eller privat afhentning. Vores supportteam kan spørge efter yderligere dokumentation.",
			BuyerProtection_Faq_Nine_Title: "Hvad gør jeg, hvis jeg finder en fejl med varen senere end 3 dage efter modtagelsen eller afhentningen?",
			BuyerProtection_Faq_Nine_Text: "Når du modtager eller afhenter varen, bør du kigge den igennem for at konstatere, om alt er, som det skal være. Går der flere dage, kan det blive svært for Reshopper at vurdere, om standen er ændret, fordi varen er taget i brug, eller fordi den var anderledes end beskrevet. Er der gået mere end 3 dage, så skal du kontakte sælger og se, om I kan finde en løsning sammen.",


			LogIn_Text: "Log ind",
			LogIn_Email: "E-mail",
			LogIn_Password: "Adgangskode",
			LogIn_Error: "Login fejlede. Prøv venligst igen.",
			New_User_Text: "Ny bruger?",
			LinkText_Download_App: "Hent vores app her",
			LinkText_Go_To_Frontpage: "Gå til forsiden",
			ButtonText_Download_App: "Hent app",
			See_More_Text: "Se mere",
			See_Less_Text: "Se mindre",

			Credits_Content_InfoText: "Brug credits til at booste dine varer, og opnå hurtigere salg.",
			Credits_Banner_TitleText1: "Få",
			Credits_Banner_TitleText2: "<span>med</span> Reshopper",
			Credits_Banner_Text: "Derudover får du 40% rabat på alle overskudsvarer, billigere fragt, 25% rabat på køberbeskyttelse, søgeprioritering og ingen reklamer",
			Credits_Banner_Btn: "Læs mere",
			Confirmation_Credits_SuccesMessageTitle: "Gennemført!",
			Confirmation_Credits_SuccesMessage: "Dine credits er nu indsat på din konto.<br/>Du kan nu vende tilbage til appen 🙂",

			Account_Reset_Password_Title: "Skift password",
			Account_Reset_Password_Hint: "Nyt password",
			Account_Reset_Password_NoPasswordSpecified: "Password skal angives.",
			Account_Reset_Password_LinkExpired: "Password-linket er udløbet.",
			Account_Reset_Password_PasswordMustBeAtLeastCharacters: "Minimum {{length}} karakterer.",
			Account_Reset_Password_Update: "Gem",
			Information_Card_Payment_On_File: "Obs - kender vi dine betalingsoplysninger har vi udfyldt dem for dig, så du blot skal indtaste kontrolcifrene 🙂 \n \nOBS: Du kan tilføje nyt kort via indstillingerne: \"Dig\" → \"Indstillinger\" → \"Konto\"",

			Web_Dialog_Confirm: "Bekræft",
			Web_Dialog_Cancel: "Fortryd",

			Update_Available_Header: "Tid til en opdatering",
			Update_Available_Subheader: "Ups, det ser ud til, at din Reshopper-app trænger til en opdatering.",
			Update_Available_Text: "Det er en god idé at opdatere appen, hvis du ønsker den bedste Reshopper-oplevelse og adgang til alle vores nye funktioner. Det tager kun et øjeblik.",
			Update_Available_ButtonText: "Opdatér nu",
			Update_Available_AndroidText: "Find opdateringen i Play Store.",

			Receipts_NotFound: "Vi fandt desværre ingen kvittering på dette link.",
			Payment_Error_Toast: "Der opstod en fejl ved betaling: {{error}}"
		}
	}
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		supportedLngs: ["en", "da"],
		fallbackLng: "da",
		keySeparator: false,
		interpolation: {
			// react already saves us from xss
			escapeValue: false
		}
	});

var languageCode = typeof navigator !== "undefined" ?
	(navigator.language || (navigator as any).userLanguage || "en").split('-')[0] :
	"en";

setLanguage(languageCode);
i18n.changeLanguage(languageCode)

export default i18n;